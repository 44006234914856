const getPersonFromObject = (obj) => {
  if (!obj) {
    return {};
  }

  const data = {
    firstName: obj.firstName,
    lastName: obj.lastName,
    phone: obj.phone,
    email: obj.email
  }

  if (obj.id) {
    data.id = obj.id
  }

  return data;
};

const getAddressFromObject = (obj) => {
  if (!obj) {
    return {};
  }

  const data = {
    street: obj.street,
    streetNr: obj.streetNr,
    postalCode: obj.postalCode,
    city: obj.city
  }

  if (obj.addressId) {
    data.id = obj.addressId
  }

  return data;
};

const getPersonWithAddress = (obj, type, flatAddress) => {
  const tmpAddress = obj.address ? obj.address : obj;
  const address = flatAddress ? getAddressFromObject(tmpAddress) : { address: getAddressFromObject(tmpAddress) }
  return Object.assign(
    getPersonFromObject(obj), 
    { type: type }, 
    address
  );
};

const getPersonByType = (persons, type, flatAddress) => {
  let person = persons.find(item => item.type === type);
  if (person) {
    if (flatAddress) {
      person = Object.assign({}, person, getAddressFromObject(person.address));
    }
    return person;
  }
  return null;
}

const getFileByType = (store, type) => {
  return store.getters.corporateCaseData?.file.filter(f => f.type === type);
};

export { getPersonFromObject, getAddressFromObject, getPersonWithAddress, getPersonByType, getFileByType }