import { createStore } from "vuex";
import BaseService from "@/services/base.service";
import router from "@/router/index";
import { getAddressFromObject } from '@/composables/useGetters.js';

export default createStore({
  state: {
    authenticated: false,
    token: null,
    activeComponent: null,
    clerkData: {
      cases: [],
      corporateCases: []
    },
    caseData: null,
    wvpCaseData: null,
    corporateCaseData: null,
    userData: null,
    loading: false,
    formValid: false,
    scopedFormValid: false,
    interactionState: false,
    frontendState: {
      childClickState: "INITIAL",
      alimonyClickState: "INITIAL",
      occupationClickState: "INITIAL",
      otherIncomeClickState: "INITIAL",
      assetClickState: "INITIAL",
    },
    showInformationModal: false,
    modalInfoTextKey: null,
    forms: {},
    corporatePersons: [],
    activeCase: {},
    dropdowns: [],
    showInfoModal: false,
    infoModalText: null,
  },
  getters: {
    authenticated: (state) => state.authenticated,
    token: (state) => state.token,
    role: (state) => state.userData?.role,
    activeComponent: (state) => state.activeComponent,
    caseData: (state) => state.caseData,
    wvpCaseData: (state) => state.wvpCaseData,
    corporateCaseData: (state) => state.corporateCaseData,
    corporateCaseId: (state) => state.userData.corpCaseId,
    corporateCaseCurrentState: (state) => state.corporateCaseData?.currentState,
    userData: (state) => state.userData,
    loading: (state) => state.loading,
    formValid: (state) => state.formValid,
    scopedFormValid: (state) => state.scopedFormValid,
    interactionState: (state) => state.interactionState,
    frontendState: (state) => state.frontendState,
    forms: (state) => state.forms,
    corporatePersons: (state) => state.corporatePersons,
    // getCorporatePersonByType: (state) => (type, flatAddress) => {
    //   let person = state.corporatePersons.find(item => item.type === type);
    //   console.log(person)
    //   if (person) {
    //     if (flatAddress) {
    //       person = Object.assign({}, person, getAddressFromObject(person.address));
    //     }
    //     return person;
    //   }
    //   return null;
    // },
    getCorporatePersonsByType: (state) => (type, flatAddress) => {
      let person = state.corporatePersons.find(item => item.type === type);
      if (person) {
        if (flatAddress) {
          person = Object.assign({}, person, getAddressFromObject(person.address));
        }
        return person;
      }
      return null;
    },
    dropdownByType: (state) => (type) => {
      return state.dropdowns.filter(item => item.dropdownName === type)
    }
  },
  mutations: {
    authenticate(state, { token, role }) {
      state.authenticated = true;
      state.token = token;
      state.role = role;
    },
    setActiveComponent(state, comp) {
      state.activeComponent = comp;
    },
    removeActiveComponent(state) {
      state.activeComponent = null;
    },
    setCaseData(state, data) {
      state.caseData = data;
    },
    setWvpCaseData(state, data) {
      state.wvpCaseData = data;
    },
    setUserData(state, data) {
      state.userData = data;
    },
    triggerLoading(state) {
      state.loading = !state.loading;
    },
    setFormValid(state) {
      state.formValid = true;
    },
    setFormInvalid(state) {
      state.formValid = false;
    },
    setInteractionStateValid(state) {
      state.interactionState = true;
    },
    setInteractionStateInvalid(state) {
      state.interactionState = false;
    },
    setChildClickState(state, data) {
      state.frontendState.childClickState = data;
    },
    setAlimonyClickState(state, data) {
      state.frontendState.alimonyClickState = data;
    },
    setOccupationClickState(state, data) {
      state.frontendState.occupationClickState = data;
    },
    setOtherIncomeClickState(state, data) {
      state.frontendState.otherIncomeClickState = data;
    },
    setAssetClickState(state, data) {
      state.frontendState.assetClickState = data;
    },
    setCurrentState(state, data) {
      state.frontendState.currentState = data;
    },
    setCorporateCurrentState(state, data) {
      state.corporateCaseData.currentState = data;
    },
    closeInformationModal(state) {
      state.showInformationModal = false;
    },
    showInformationModal(state, data) {
      state.modalInfoTextKey = data;
      state.showInformationModal = true;
    },
    registerForm(state, data) {
      if (data.value.scoped) {
        state.scopedFormValid = false;
      }
      state.forms[data.key] = data.value;
    },
    unregisterForm(state, hash) {
      delete state.forms[hash];
    },
    unregisterScopedForms(state) {
      for (const [key, value] of Object.entries(state.forms)) {
        if (value.scoped) {
          delete state.forms[key];
        }
      }
      state.scopedFormValid = false;
    },
    setCorporateCaseData(state, data) {
      state.corporateCaseData = data;
    },
    setActiveCase(state, caseData) {
      state.activeCase = caseData;
    }
  },
  actions: {
    async retrieveUserData({ state, commit }) {
      return new Promise((resolve, reject) => {
        BaseService.retrieve("/current-user")
          .then((res) => {
            state.userData = res.data;
            if (state.userData.role === "clerk") {
              router.push({ name: "corporate-dashboard" });
            } else if (state.userData.role === "debtor") {
              let routeName = "home";

              if (state.userData.wvpYear && state.userData.wvpCaseId) {
                routeName = "wvp-home";
              }

              router.push({ name: routeName });
            } else if (state.userData.role === "admin") {
              router.push("/admin/management/user");
            } else if (state.userData.role === "corp_debtor") {
              router.push({ name: 'corporate-entry' });
            }
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    retrieveCaseData({ state, commit }) {
      BaseService.find("/cases", state.userData.caseId)
        .then((res) => {
          commit("setCaseData", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    retrieveFrontendState({ state }) {
      BaseService.retrieve(`/cases/${state.userData.caseId}/frontend-state`)
        .then((frontendState) => {
          state.frontendState = frontendState.data;
          state.currentState = frontendState.data.currentState;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    retrieveWvpCaseData({ state, commit }) {
      BaseService.get(
        `/cases/${state.userData.caseId}/wvp-cases/${state.userData.wvpCaseId}`
      )
        .then((res) => {
          commit("setWvpCaseData", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    retrieveCorporateCaseData({ state, commit }) {
      BaseService.get(
        `/corp/cases/${state.userData.corpCaseId}`
      )
        .then((res) => {
          if (res.data.companyInfo.managementSeat) {
            res.data.companyInfo.managingDirectorAddress = true;
          } else {
            res.data.companyInfo.managingDirectorAddress = false;
          }
          
          commit("setCorporateCaseData", res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      BaseService.get(
        `/corp/cases/${state.userData.corpCaseId}/persons`
      )
        .then((res) => {
          state.corporatePersons = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      BaseService
        .get('/corp/dropdown-options')
        .then((res) => {
          state.dropdowns = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    retrieveWvpFrontendState({ state }) {
      BaseService.retrieve(
        `/cases/${state.userData.caseId}/wvp-frontend-state/${state.userData.wvpCaseId}`
      )
        .then((frontendState) => {
          state.frontendState = frontendState.data;
          state.currentState = frontendState.data.currentState;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateFormValidity({ state }) {
      let tmpScopedValid = false;
      let tmpValid = false;
      for (const [key, value] of Object.entries(state.forms)) {
        if (value.scoped) {
          tmpScopedValid |= value.form.$invalid;
        } else {
          tmpValid |= value.form.$invalid;
        }
      }
      state.formValid = !tmpValid;
      state.scopedFormValid = !tmpScopedValid;
    },
    retrieveClerkData({ state }) {
      const getStatus = (data) => {
        if (!data.user?.firstLogin) {
          return "RED";
        } else if (data.user?.firstLogin && !data.dataSent) {
          return "ORANGE";
        } else {
          return "GREEN";
        }
      };
      BaseService
        .retrieve('/cases')
        .then(resp => {
          resp.data.forEach(i => i.status = getStatus(i));
          state.clerkData.cases = resp.data;
        });

      BaseService
        .retrieve('/corp/cases')
        .then(resp => {
          resp.data.forEach(i => i.status = getStatus(i));
          state.clerkData.corporateCases = resp.data;
        });
    }
  },
  modules: {},
});
